import fetch from "auth/FetchInterceptor";

const ActivityService = {};

ActivityService.GetSSR = async function (data) {
  return fetch({
    url: "/Activity/GetAllSSR",
    method: "post",
    data: data,
  });
};
ActivityService.Create = function (data) {
  return fetch({
    url: "/Activity/Create",
    method: "post",
    data,
  });
};

ActivityService.Update = function (data) {
  return fetch({
    url: "/Activity/Update",
    method: "post",
    data,
  });
};
ActivityService.Activate = function (params) {
  return fetch({
    url: "/Activity/Activate",
    method: "post",
    params,
  });
};
ActivityService.GetAll = function () {
  return fetch({
    url: "/Activity/GetAll",
    method: "get",
  });
};
ActivityService.GetById = function (params) {
  return fetch({
    url: "/Activity/GetById",
    method: "get",
    params,
  });
};

export default ActivityService;
